/* src/pages/ReviewsPage/ReviewsPage.css */
.reviews-page {
  padding: 200px;
  font-family: 'Montserrat', Arial, sans-serif;
  text-align: center;
}

.reviews-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.review-item {
  background-color: #EFE8E0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.review-item h3 {
  font-size: 20px;
  color: #7F6044;
}

.review-item p {
  font-size: 16px;
  color: #555;
}

.review-item em {
  color: #999;
}
