.social-button {
    position: fixed;
    margin-left: 10px;
    color: white;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.social-button:hover {
    transform: scale(1.1);
}


.social-button.whatsapp {
    background-color: #25D366;
    bottom: 85px;
    right: 20px;
}


.social-button.instagram {
    background-color: #E1306C;
    bottom: 20px;
    right: 20px;
}


.social-buttons-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
}
