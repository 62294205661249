/* src/index.css */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap'); */

body, html {
  /* font-family: 'Montserrat', sans-serif; */
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Garante que o fundo cubra toda a altura da tela */
  box-sizing: border-box; /* Garante que padding e bordas não aumentem o tamanho dos elementos */
  color: #7F6044
}
