/* Estilo principal para as páginas */
.page {
    padding: 200px;
    font-family: 'Montserrat', Arial, sans-serif;
    text-align: center;
}

.page h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #7F6044; /* Mesma cor para títulos em ambas as páginas */
}

/* Listagem de serviços e produtos */
.list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Estilo compartilhado para serviços e produtos */
.item-card {
    background-color: #EFE8E0;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 300px;
    margin: 20px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    text-align: left;
}

.item-card:hover {
    transform: scale(1.05);
}

.item-card img {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 10px;
}

/* Informações dos itens (serviços ou produtos) */
.item-info {
    margin-top: 15px;
}

.item-info h2 {
    font-size: 24px;
    color: #7F6044; /* Cor do título consistente */
}

.item-info p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    margin: 10px 0;
}

/* Links de ação (botões) para serviços ou produtos */
.item-link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.item-image {

}

