.footer {
  background-color: #EFE8E0;
  padding: 20px 0;
  text-align: center;
  color: #7F6044;
  margin-left: 55px;
}

.footer-container {
  border-top: 1px solid #a39789;
  border-bottom: 1px solid #a39789;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  gap: 20px;
}

.footer-logo img {
  height: 60px;
}

.footer-menu, .footer-social {
  text-align: center;
}

.footer-menu h3, .footer-social h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #7F6044;
}

.footer-menu ul {
  list-style: none;
  padding: 0;
}

.footer-menu ul li {
  margin-bottom: 5px;
}

.footer-menu ul li a {
  text-decoration: none;
  color: #7F6044;
}

.footer-social .social-icon {
  font-size: 30px;
  color: #7F6044;
  margin-right: 15px;
}

.footer-rights {
  margin-top: 15px;
  font-size: 0.9rem;
  margin-right: 35px;
}

.footer-rights a {
  text-decoration: none;
  color: #7F6044;
}

/* Responsivo - para telas menores */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-logo, .footer-menu, .footer-social {
    margin-bottom: 20px;
  }
}
